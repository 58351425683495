<template>
  <!-- My 강의실 -->
  <coach-my-page-content-layout user-classes>
    UserClasses
  </coach-my-page-content-layout>
</template>

<script>
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout';

export default {
  name: 'UserClasses',
  components: { CoachMyPageContentLayout },
};
</script>
